import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    padding: 20px;
`

const SplitContainer = styled.div`
    display: flex;
    flex-direction: row;
    @media(max-width: 747px) {
        flex-direction: column;
    }
`

const Title = styled.h3`
`

const Text = styled.p`
    font-size: 14px;
`

const Paragraph = styled.div`
    flex: 50%;
    display: flex;
    max-width: 700px;
    flex-direction: column;

`

const ImageContainer = styled.div`
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Image = styled.div`
    width: 100px;
    height: 100px;
    background-image: ${props => `url(${props.img})`};
    background-position: 0cm;
    border-radius: 100%;
    background-size: cover;
`

export default function SmallParagraphFeature({ title, text, img }) {
    return (
        <Container>
            <SplitContainer>
                <ImageContainer>
                    <Image img={img} />
                </ImageContainer>
                <Paragraph>
                    <Title>{title}</Title>
                    <Text>{text}</Text>
                </Paragraph>
            </SplitContainer>
        </Container>
    )
}
