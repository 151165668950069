import React, { useContext } from 'react'
import Layout from '../../../components/layout'
import DotDivider from '../../../components/layout/DotDivider';
import Footer from '../../../components/layout/Footer';
import HeroContainer from '../../../components/layout/HeroContainer'
import Offer from '../../../components/layout/Offer';
import ParagraphWithTitle from '../../../components/layout/ParagraphWithTitle';
import SimilarOffers from '../../../components/layout/SimilarOffers';
import SmallParagraphFeature from '../../../components/layout/SmallParagraphFeature';
import VerticalList from '../../../components/layout/VerticalList';
import { AppContext } from '../../../contexts/AppContext';
import produce from '../../../images/private_charging/produce.png'
import store from '../../../images/private_charging/store.png'
import consume from '../../../images/private_charging/consume.png'
import SEO from '../../../components/seo';


export default function PrivateCharging() {

    const { appState } = useContext(AppContext);

    return (
        <Layout>
            <SEO title={appState.i18n.privateCharging.title} />
            <HeroContainer  title={appState.i18n.privateCharging.title} subtitle={appState.i18n.privateCharging.text} id="private-charging" />
            <DotDivider />
            <ParagraphWithTitle title={appState.i18n.privateCharging.ourVisionTitle} texts={[appState.i18n.privateCharging.ourVisionText]} />
            <VerticalList>
                <SmallParagraphFeature title={appState.i18n.privateCharging.produceTitle} text={appState.i18n.privateCharging.produceText} img={produce} />
                <SmallParagraphFeature title={appState.i18n.privateCharging.storeTitle} text={appState.i18n.privateCharging.storeText} img={store} />
                <SmallParagraphFeature title={appState.i18n.privateCharging.useTitle} text={appState.i18n.privateCharging.useText} img={consume} />
            </VerticalList>
            <DotDivider />
            <SimilarOffers title={appState.i18n.heroContainers.solutionsTitle}>
                <Offer link={"/solutions/private/powerpack"} title={appState.i18n.similarOffers.powerpacksTitle} text={appState.i18n.similarOffers.powerpacksText} />
                {/* <Offer link={"/solutions/private/powerbox"} title={appState.i18n.similarOffers.powerboxesTitle} text={appState.i18n.similarOffers.powerboxesText} /> */}
            </SimilarOffers>
            <Footer />
        </Layout>

    )
}
